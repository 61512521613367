import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './pages/Navbar';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Package from './pages/Package';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Service from './pages/Service';
import Webdesign from './pages/Webdesign';
import Wordpressdevelopment from './pages/Wordpressdevelopment';
import Ecommercedevelopement from './pages/Ecommercedevelopement';
import Webapplicationdevelopment from './pages/Webapplicationdevelopment';
import Backeneddevelopment from './pages/Backeneddevelopment';
import Branding from './pages/Branding';
import SEO from './pages/SEO';
import Footer from './pages/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/service" element={<Service/>} />
        <Route exact path="/web-design" element={<Webdesign/>} />
        <Route exact path="/wordpress-development" element={<Wordpressdevelopment/>} />
        <Route exact path="/ecommerce-development" element={<Ecommercedevelopement/>} />
        <Route exact path="/web-application-development" element={<Webapplicationdevelopment/>} />
        <Route exact path="/backend-development" element={<Backeneddevelopment/>} />
        <Route exact path="/branding" element={<Branding/>} />
        <Route exact path="/seo" element={<SEO/>} />
        <Route exact path="/packages" element={<Package/>} />
        <Route exact path="/portfolio" element={<Portfolio/>} />
        <Route exact path="/contact" element={<Contact/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
