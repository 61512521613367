import React from "react";
import { Link } from "react-router-dom";
import webdesign from '../images/10.png';
import development from '../images/11.png';
import wordpress from '../images/12.png';
import react from '../images/13.png';

const Service_card = () => {
    return (
        <>
            <section className="services style-3 section-padding pb-70">
                <div className="container">
                    <div className="section-head style-3 d-flex align-items-center">
                        <h3>Our Main <span>Services</span></h3>
                        <Link to="/service" className="text-muted ms-5 ps-5 mt-2">See full
                            services <i className="bi bi-chevron-right ms-1"></i></Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 mb-30 mb-lg-0">
                            <div className="service-card style-3">
                                <div className="icon">
                                    <img src={webdesign} alt="" />
                                </div>
                                <div className="info">
                                    <h5 className="title">Web Design</h5>
                                    <div className="text">
                                        You don't have to look any further for a website composition organization that can get your webpage live very quickly.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-30 mb-lg-0">
                            <div className="service-card style-3">
                                <div className="icon">
                                    <img src={development} alt="" />
                                </div>
                                <div className="info">
                                    <h5 className="title">Web Development</h5>
                                    <div className="text">
                                        Site Imaginative is a trailblazer in WordPress improvement administrations, and we can assist you with...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-30 mb-lg-0">
                            <div className="service-card style-3">
                                <div className="icon">
                                    <img src={wordpress} alt="" />
                                </div>
                                <div className="info">
                                    <h5 className="title">Backend Development</h5>
                                    <div className="text">
                                        We have had the option to furnish our clients with gainful Web Application Advancement Administrations...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-30 mb-lg-0">
                            <div className="service-card style-3">
                                <div className="icon">
                                    <img src={react} alt="" />
                                </div>
                                <div className="info">
                                    <h5 className="title">Branding</h5>
                                    <div className="text">
                                        Having a complex website is excessive. Our group of brand advancement specialists, examiners, and plan...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <Link class="btn rounded-pill bg-blue2 sm-butn mt-60 text-white" to="/portfolio"><span>See All Projects</span></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service_card;