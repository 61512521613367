import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer class="style-3">
                <div class="container">
                    <div class="row gx-0 justify-content-between">
                        <div class="col-lg-3">
                            <div class="items">
                                <div class="title">
                                    About Infinix Software
                                </div>
                                <small class="text">
                                    Over 5 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </small>
                                <div class="socail-icons">
                                    <Link to="#" class="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2">
                                        <i class="fa fa-twitter"></i>
                                    </Link>
                                    <Link to="#" class="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2">
                                        <i class="fa fa-facebook-f"></i>
                                    </Link>
                                    <Link to="#" class="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray">
                                        <i class="fa fa-instagram"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="items">
                                <div class="title">
                                    Information
                                </div>
                                <small class="text mb-10 d-block">
                                    10/1 Collin Lane Kolkata Kolkata WB 700016 In <br />
                                    CIN - U72900WB2019PTC234541
                                </small>
                                <small class="text mb-10 d-block">
                                    +91 7980717542
                                </small>
                                <small class="text d-block">
                                    info@infinixsoftwares.com
                                </small>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="items">
                                <div class="title">
                                    Useful Links
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li>
                                        <Link to="/about"> About Infinix </Link>
                                    </li>
                                    <li>
                                        <Link to="/service"> Services </Link>
                                    </li>
                                    <li>
                                        <Link to="/portfolio"> Portfolio </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact"> Contact Us </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="items">
                                <div class="title">
                                    Services
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/web-design"> Web Design </Link>
                                    </li>
                                    <li>
                                        <Link to="/wordpress-development"> Wordpress Development </Link>
                                    </li>
                                    <li>
                                        <Link to="#/ecommerce-development"> Ecommerce Development </Link>
                                    </li>
                                    <li>
                                        <Link to="/web-application-development"> Web Development </Link>
                                    </li>
                                    <li>
                                        <Link to="/backend-development"> Backend Development </Link>
                                    </li>
                                    <li>
                                        <Link to="/branding"> Branding </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="foot">
                        <div class="row">
                            <div class="col-lg-12">
                                <small class="small">
                                    © 2022 Copyrights by <strong>Infinix software.</strong> All Rights Reserved.
                                </small>
                            </div>
                        </div>
                        <img src="img/testimonials/testi3_lines.png" alt="" class="testi_lines w-100" />
                    </div>
                </div>
                <img src="img/contact_globe.svg" alt="" class="contact_globe" />
            </footer>
        </>
    )
}

export default Footer;
