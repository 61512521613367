import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import Ecommercedevelopementbanner from '../images/services/ecommerce-banner.jpg';
import Commonservices from "./Commonservices";
import ecommerceright from '../images/services/ecommerceright.jpg';
import ecommerce2 from '../images/services/ecommerce2.jpg';

const Ecommercedevelopement = () => {
    return (
        <>
            <Innerbanner 
            title="The Development Of Online business To Address The Needs." 
            homebread="Home" 
            breadcontent="Services / Ecommerce Development"
            imgsrc={Ecommercedevelopementbanner} />
            <Commonservices title="Ecommerce Website"
                subtitle="Development"
                discription="Extend your internet based store's worldwide presence, Looking to Give Consistent Web-based Insight. Conveying totally internationalized destinations that are adjusted to your nearby business sectors can expand your viewpoints and extend the size of your purchaser base. Our worldwide internet business choices are an incredible method for extending your organization's span. Draw nearer to your clients by rejuvenating your image and things on the web and supporting your deals. To accomplish our objectives, we adopt a result situated strategy and utilize a nimble technique that permits us to protect adaptability while likewise monitoring everything. We're focused on serving you with a CAN-DO demeanor. This implies that you can depend on us for direction that is lined up with your business objectives."        
                alt="Ecommerce Website Development"
                imgsrc={ecommerceright}
                titlesecond="Complete web stack" 
                subtitlesecond="advancements"
                discription1="Server-side and client-side advances and instruments that can be utilized to streamline web application speed and the client experience."
                discription2="An upper hand and the acknowledgment of your vision are the two things you want from the procedure and improvement administrations you recruit. At the point when you enlist infinix software, you'll approach a staff that is conversant in excess of 50 different programming dialects and structures."
                discription3="Our answers are customized to your exceptional necessities, instead of the cutout approaches of contending firms."
                imgsrcsecond={ecommerce2} />
            <Chat_card />
        </>
    )
}

export default Ecommercedevelopement;