import React from "react";
import { Link, NavLink } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    return (
        <>
            <nav className="nav-new" id="desktop-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="body-widget">
                                <Link to="/">
                                    <img src="img/logo.png" className="logo" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="menu-right">
                                <div className="body-widget">
                                    <Link id="resp-menu" class="responsive-menu" to="#"><i class="fa fa-reorder"></i></Link>
                                    <ul className="menu-new">
                                        <li><NavLink to="/" end >Home</NavLink></li>
                                        <li><NavLink to="/about" end>About Us</NavLink></li>
                                        <li>
                                            <NavLink to="/service" end>Services</NavLink>
                                            <ul>
                                                <li><NavLink to="/web-design" end>Web Design</NavLink></li>
                                                <li><NavLink to="/wordpress-development" end>Wordpress Development</NavLink></li>
                                                <li><NavLink to="/ecommerce-development" end>Ecommerce Website Development</NavLink></li>
                                                <li><NavLink to="/web-application-development" end>Web Application Development</NavLink></li>
                                                <li><NavLink to="/backend-development" end>Backend Development</NavLink></li>
                                                {/* <li><NavLink to="/domain-hosting" end>Domain and Hosting</NavLink></li> */}
                                                <li><NavLink to="/branding" end>Branding</NavLink></li>
                                                {/* <li><NavLink to="/seo" end>Search Engine Optimization</NavLink></li> */}
                                            </ul>
                                        </li>
                                        <li><NavLink to="/portfolio" end>Portfolio</NavLink></li>
                                        {/* <li><NavLink to="/technologies" end>Technologies</NavLink></li> */}
                                        <li><NavLink to="/packages" end>Packages</NavLink></li>
                                        <li><NavLink to="/contact" end>Contact</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;