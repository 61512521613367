import portfolio1 from '../images/portfolio/portfolio1.jpg';
import portfolio2 from '../images/portfolio/porfolio_equal.jpg';
import portfolio3 from '../images/portfolio/porfolio_equal2.jpg';
import portfolio4 from '../images/portfolio/porfolio_equal3.jpg';


const Pcard = [
    {
        imgsrc:portfolio1,
        title:"Get An Intervention",
        discription:"Lorem ipsum is simply dummy text",
        // more:"Visit",
    },
    {
        imgsrc:portfolio2,
        title:"V2s Payments",
        discription:"Lorem ipsum is simply dummy text",
    },
    {
        imgsrc:portfolio3,
        title:"Hello Bpndhu",
        discription:"Lorem ipsum is simply dummy text",
    },
    {
        imgsrc:portfolio4,
        title:"Minerals",
        discription:"Lorem ipsum is simply dummy text",
    },
];


export default Pcard;