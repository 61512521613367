import service1 from '../images/10.png';
import service2 from '../images/11.png';
import service3 from '../images/12.png';
import service4 from '../images/12.png';
import service5 from '../images/12.png';
import service6 from '../images/12.png';
import service7 from '../images/12.png';
import service8 from '../images/12.png';
import service9 from '../images/12.png';

const Scard = [
    {
        imgsrc:service1,
        title:"Web Design",
        discription:"You don't have to look any further for a website composition organization that can get your webpage...",
    },
    {
        imgsrc:service2,
        title:"Wordpress Development",
        discription:"Site Imaginative is a trailblazer in WordPress improvement administrations, and we can assist you...",
    },
    {
        imgsrc:service3,
        title:"Ecommerce Website Development",
        discription:"Extend your internet based store's worldwide presence, Looking to Give Consistent Web-based Insight...",
    },
    {
        imgsrc:service4,
        title:"Web Application Development",
        discription:"Administrations for Creating Interesting Web Applications, Design is strong A web application improvement...",
    },
    {
        imgsrc:service5,
        title:"Backend Development",
        discription:"We have had the option to furnish our clients with gainful Web Application Advancement Administrations...",
    },
    {
        imgsrc:service7,
        title:"Branding",
        discription:"Having a complex website is excessive. Our group of brand advancement specialists, examiners, and plan...",
    },
];

export default Scard;