import React from "react";
import { Link } from "react-router-dom";

const Commonport = (props) => {
    return (
        <>
            <div className="col-sm-4">
                <div className="card">
                    <img src={props.imgsrc} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">{props.title}</h5>
                        <p className="card-text">
                            {props.discription}
                        </p>
                        <Link to="/">{props.more}</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Commonport;