import React from "react";
import { Link } from "react-router-dom";

const Chat_card = () => {
    return (
        <>
            <section className="chat-banner style-3 section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="info">
                                <h3>Web-driven advancement with state of the art devices to <span>tackle your concern.</span></h3>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="bttns text-end">
                                <Link to="/contact" className="btn rounded-pill bg-white border-1 border-white text-dark sm-butn me-2">
                                    <span>Let’s Chat</span>
                                </Link>
                                <Link to="/service" className="btn rounded-pill border-1 border-white text-white sm-butn">
                                    <span>Get Information</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Chat_card;