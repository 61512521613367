import React from "react";
import { Link } from "react-router-dom";
import './banner.css';

const Banner = () => {
    return (
        <>
            <header class="style-3 overflow-hidden">
                <div class="container">
                    <div class="content section-padding">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="info">
                                    <h1 class="h1">Create to plan your business from <span>Infinix</span></h1>
                                    <p class="p">Increase productivity with simple to-do app. app to manage
                                        your personal budgets.</p>
                                    <h5 class="h5">Get Free Quote! <span class="fw-normal ms-1">We’ll get back you 1 working day.</span>
                                    </h5>
                                    <form action="#" class="form mt-10" method="post">
                                        <div class="row gx-3">
                                            <div class="col-12">
                                                <Link to="/about" class="btn dark-butn hover-darkBlue rounded-pill w-50 mt-3">
                                                    <span>Read More</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-img">
                    <img src="img/header/header_3.png" alt="" class="pattern" />
                    <img src="img/header/header_3_c.png" alt="" class="circle" />
                    <img src="img/header/logo_sh_l.png" alt="" class="logo_shap" />
                </div>
            </header>
        </>
    )
}

export default Banner;