import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import SEObanner from '../images/services/seo-banner.jpg';
import Commonservices from "./Commonservices";
import branding from '../images/services/branding.jpg';
import expert from '../images/services/expert.jpg';

const SEO = () => {
    return (
        <>
            <Innerbanner 
            title="Make An Outwardly Engaging Site That Exhibits Your Idea." 
            homebread="Home" 
            breadcontent="Services / Wordpress Development"
            imgsrc={SEObanner} />
            <Commonservices title="Brand"
                subtitle="Branding"
                discription="Having a complex website is excessive. Our group of brand advancement specialists, examiners, and plan devotees loves to utilize powerful brand character creation answers for impart the tale of your organization. We foster brands that are immortal and can possibly form into pivotal names. The characters we create are intended to leave follows in the minds of your objective market, empowering you to have an enduring impression. Converse with us right now about your image personality improvement thoughts."        
                alt="Branding"
                imgsrc={branding}
                titlesecond="We are specialists to mark your" 
                subtitlesecond="business"
                discription1="Set of innovations and instruments that can be utilized during server-side and client-side web application improvement administrations to guarantee the best exhibition and client experience"
                discription2="You want system and improvement benefits that adjust your vision and give you the edge against contenders. With infinix software, you'll get a group with experience utilizing north of 50 distinct improvement innovations."
                discription3="Dissimilar to different organizations who present just the thoughts they know how to construct, our answers are exceptionally fit to your concerns."
                imgsrcsecond={expert} />
            <Chat_card />
        </>
    )
}

export default SEO;