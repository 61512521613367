import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import Backeneddevelopmentbanner from '../images/services/backend-banner.jpg';
import Commonservices from "./Commonservices";
import backright from '../images/services/backright.jpg';
import backcode from '../images/services/backcode.jpg';

const Backeneddevelopment = () => {
    return (
        <>
            <Innerbanner 
            title="We Provide Innovative Brand Development Services." 
            homebread="Home" 
            breadcontent="Services / Backend Development"
            imgsrc={Backeneddevelopmentbanner} />
            <Commonservices title="Backend"
                subtitle="Development"
                discription="We have had the option to furnish our clients with gainful Web Application Advancement Administrations because of the help of our exceptionally qualified and gifted Backend engineers. Everything from a clear site with a Substance The board Framework to the most confounded online web applications, web based business entries, and person to person communication improvement administrations are remembered for our web application advancement and custom programming administrations. We have long stretches of involvement making on the web applications using state of the art devices like PHP, MySQL, ASP.NET, and numerous others. Our broad experience includes fostering an assortment of web applications for independent ventures to enormous enterprises. Also, we are seasoned veterans of planning and making web applications that address the issues of the client."        
                alt="Web Application Development"
                imgsrc={backright}
                titlesecond="Complete web stack" 
                subtitlesecond="advancements"
                discription1="Server-side and client-side advances and instruments that can be utilized to streamline web application speed and the client experience."
                discription2="An upper hand and the acknowledgment of your vision are the two things you want from the procedure and improvement administrations you recruit. At the point when you enlist infinix software, you'll approach a staff that is conversant in excess of 50 different programming dialects and structures."
                discription3="Our answers are customized to your exceptional necessities, instead of the cutout approaches of contending firms."
                imgsrcsecond={backcode} />            
            <Chat_card />
        </>
    )
}

export default Backeneddevelopment;