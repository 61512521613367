import React from "react";
import Chat_card from "./Chat_card";
import Innerbanner from "./Innerbanner";
import './about.css';
import Aboutbanner from '../images/innerbanner/aboutbanner.jpg';
import about from '../images/about.png';
import abouticon from '../images/about_icon1.png';
import abouticon2 from '../images/about_icon2.png';
import abouticon3 from '../images/about_icon3.png';

const About = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Innerbanner
                title="We specialise for our customers their business"
                homebread="Home"
                breadcontent="About Us"
                imgsrc={Aboutbanner} />
            <section id="about_area" className="about section-padding style-5 style-6">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="about_widget">
                                <img src={about} alt="" />
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="about_widget">
                                <div className="section-head style-3 d-flex align-items-center">
                                    <h3>Who We <span>Are?</span></h3>
                                </div>
                                <div className="text">
                                    <p>
                                        We are devotees of progress! A change driven by innovation and development. We help organizations and people in adjusting as well as embracing advanced change. Our point is to transform people and further develop organizations with our moderate and imaginative innovation arrangements.
                                    </p>
                                    <p>
                                        We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying.
                                    </p>
                                    <ul>
                                        <li>We always focus on technical excellence</li>
                                        <li>Wherever you’re going, we bring ideas and excitement</li>
                                        <li>We’re consultants, guides, and partners for brands</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="stragy_area" className="stragy_area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img src={abouticon} className="about_icon" alt="..." />
                                    <h5 className="card-title">Strategy & Discovery</h5>
                                    <p className="card-text">
                                        We provide the most responsive and functional IT design for companies and businesses worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img src={abouticon2} className="about_icon" alt="..." />
                                    <h5 className="card-title">Software Solution</h5>
                                    <p className="card-text">
                                        We provide the most responsive and functional IT design for companies and businesses worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img src={abouticon3} className="about_icon" alt="..." />
                                    <h5 className="card-title">Technology Solution</h5>
                                    <p className="card-text">
                                        We provide the most responsive and functional IT design for companies and businesses worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="expe_area" className="expe_area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="expe_widget text-center">
                                <h2 className="about_title">4+</h2>
                                <h3 className="about_sub_title">Years of experience</h3>
                                <p>
                                    We run all kinds of <span>IT services</span><br /> that vow your success
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="history_area" className="history_area">
                <div className="container">
                    <div className="section-head style-3 align-items-center text-center">
                        <h3>Our <span>History</span></h3>
                    </div>
                    <div class="timeline">
                        <div class="timeline_widget left">
                            <div class="content_about">
                                <h2>2019</h2>
                                <p>
                                    We began with only 2 representatives working energetically with a dream to develop. With most extreme devotion, we stowed our most memorable brand project in the span of a month of our joining. Set the reason for progress and uncommon open doors.
                                </p>
                            </div>
                        </div>
                        <div class="timeline_widget right">
                            <div class="content_about">
                                <h2>2020</h2>
                                <p>
                                    We contacted a 20+ undertaking achievement mark and fortified our labor force by recruiting 10+ workers from across areas, the entirety of this in a year. Inclined up the entirety of our cycles to integrate a smooth progression of work.
                                </p>
                            </div>
                        </div>
                        <div class="timeline_widget left">
                            <div class="content_about">
                                <h2>2021</h2>
                                <p>
                                    We got industry and overall acknowledgment as tech specialists and naturally suspected pioneers. Our representative strength developed into a local area of tech sweethearts constructing a strong culture that will undoubtedly see just development ahead.
                                </p>
                            </div>
                        </div>
                        <div class="timeline_widget right">
                            <div class="content">
                                <h2>2022</h2>
                                <p>
                                    With a year on year development of 100%, we moved into another office to oblige our colossal strength of 10+ representatives.
                                </p>
                            </div>
                        </div>                      
                    </div>
                </div>
            </section>

            <Chat_card />
        </>
    )
}

export default About;