import React from "react";
import Innerbanner from "./Innerbanner";
import './services.css';
import Chat_card from "./Chat_card";
import Commoncard from "./Common_card";
import Scard from "./Scard";
import service from '../images/innerbanner/services.jpg';


const Service = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Innerbanner 
            title="We Assemble Advanced
            Items That Help Your
            Business" 
            homebread="Home" 
            breadcontent="Services"
            imgsrc={service} />

            <section id="service_card" className="services style-3 section-padding pb-70">
                <div className="container">
                    <div className="section-head style-3 d-flex align-items-center">
                        <h3>Our Main <span>Services</span></h3>
                    </div>
                    <div className="row gy-4">
                        {
                            Scard.map((val, ind) => {
                                return <Commoncard key={ind} imgsrc={val.imgsrc} title={val.title} discription={val.discription} />
                            })
                        }
                    </div>
                </div>
            </section>
            <Chat_card />
        </>
    )
}

export default Service;