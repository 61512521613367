import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import webdesignbanner from '../images/services/web-design-banner.jpg';
import Commonservices from "./Commonservices";
import webdesignright from '../images/services/web-design-right.jpg';
import fullstack from '../images/services/full-stack.jpg';

const Webdesign = () => {
    return (
        <>
            <Innerbanner
                title="Make An Outwardly Engaging Site That Exhibits Your Idea."
                homebread="Home"
                breadcontent="Services / Web Design"
                imgsrc={webdesignbanner} />

            <Commonservices title="Web"
                subtitle="Design"
                discription="You don't have to look any further for a website composition organization that can get your webpage live very quickly. We'll grandstand your unique contributions like web advancement, Search engine optimization, copywriting and your expert qualities too in the most clear and far reaching way. Our monetary consultant web composition makes visiting your site a charming encounter. Infinix Software web architectures are the business' highest quality level. We serve a portion of the top monetary and venture counsels in the area. As specialists in the field, our website specialists will make an enamoring, usable site that is not difficult to explore and that clients need to examine.
 Your site is a significant distinguishing mark to assist you with imparting your message and administration. Our specialists will show you how incredible website composition can make your business stand-apart from the group. We'll direct you through the interaction, ensuring that the format and plan of your site is the most appropriate to your prerequisites."
                alt="Web Design"
                imgsrc={webdesignright}
                titlesecond="advancements" 
                subtitlesecond="advancements"
                discription1="Our website composition group will likewise assist with evaluating what you really want for your specific help. This incorporates the number and kind of points of arrival expected for your business and copywriting also. We will consider your client base and foster the ideal design and route way, fit to their web-based conduct and usership."
                discription2="Similarly as significant, our sites can offer viable answers for copywriting too to our tempting expected clients, as well as speaking with existing clients. Our planners can tell you the best way to involve your site as an instrument to make more prominent associations with your base, with extra additional items, devices or specific pages. It's a significant method for offering better, more inventive help."
                discription3="Contact an Infinix Software delegate today, and begin to get redone website architecture to grandstand your significant business contributions."
                imgsrcsecond={fullstack} />


            <Chat_card />
        </>
    )
}

export default Webdesign;