import React from "react";
import { Link, } from "react-router-dom";
import './innerbanner.css';

const Innerbanner = (props) => {
    return (
        <>
            <section id="inner_banner_area" className="inner_banner_area">
                <div className="banner_widget">
                    <img src={props.imgsrc} className="slider_img" alt="Services" />
                    <div className="banner_overlay"></div>
                    <div className="banner_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/" className="bread_link">{props.homebread}</Link></li>
                                            <li class="breadcrumb-item active" aria-current="page">{props.breadcontent}</li>
                                        </ol>
                                    </nav>
                                    <h1 className="banner_title">{props.title}</h1>
                                    <Link to="" className="btn dark-butn hover-darkBlue rounded-pill w-50 mt-3">Get a Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Innerbanner;