import React from "react";
import Scard from "./Scard";

const Commoncard = (props) => {
    return (
        <>
            <div className="col-sm-4">
                <div className="service-card style-3 text-center">
                    <div className="icon">
                        <img src={props.imgsrc} alt="" />
                    </div>
                    <div className="info">
                        <h5 className="title">{props.title}</h5>
                        <div className="text">
                            {props.discription}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Commoncard;