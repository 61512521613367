import React from "react";
import { Link } from "react-router-dom";
import './price_common.css';

const Commonprice = (props) => {
    return (
        <>
            <section id="price_common" className="price_common">
                <div className="container">
                    <div className="section-head style-3 d-flex align-items-center justify-content-center">
                        <h3>Our <span>Package</span></h3>
                    </div>
                    <div className="body_widget text-center">
                        <p>
                            Take a look at our affordable packages to build a website <br /> with a greater level of functionality compared to traditional websites.
                        </p>
                    </div>
                    <div className="price-cards">
                        <div className="row my-5">
                            <div className="col-lg-4">
                                <div className="pricing-card style-3 mb-30 mb-lg-0">
                                    <div className="card-head">
                                        <div className="title">
                                            <h4>Standard</h4>
                                            <small>Starting at</small>
                                        </div>
                                        <div className="price">
                                            <h5>$199.99</h5>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <ul>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>3 Stock Photos</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>3 Banner Design</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>1 Page Static Website</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>1 jQuery Slider Banner</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>FREE Google Friendly Sitemap</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Complete W3C Certified HTML</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>48 to 72 hours TAT</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>100% Satisfaction Guarantee</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>100% Unique Design Guarantee</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Additional Mobile Responsive ($99)</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                        <span>Get Started Now</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="pricing-card dark-card style-3">
                                    <div className="card-head">
                                        <div className="title">
                                            <h4>Pro <small>Recommended</small></h4>
                                            <small>Premium features</small>
                                        </div>
                                        <div className="price">
                                            <h5>$399.99</h5>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <ul>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>8 Unique Pages Website</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>CMS / Admin Panel Support</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>8 Stock images </small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>5 Banner Designs </small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>1 jQuery Slider Banner</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>FREE Google Friendly Sitemap</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Complete W3C Certified HTML</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>48 to 72 hours TAT</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Complete Deployment</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>100% Satisfaction Guaranteet</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>100% Unique Design Guarantee </small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Additional Mobile Responsive ($99)</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                        <span>Get Started Now</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="pricing-card style-3 mb-30 mb-lg-0">
                                    <div className="card-head">
                                        <div className="title">
                                            <h4>Elite</h4>
                                            <small>Starting at</small>
                                        </div>
                                        <div className="price">
                                            <h5>$799.99</h5>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <ul>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Conceptual and Dynamic Website</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Mobile Responsive</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Online Payment Integration (Optional)</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Custom Forms</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Striking Hover Effects</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Newsfeed Integration</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Social Media Integration</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>8 Stock images </small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>5 Banner Designs </small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>1 jQuery Slider Banner</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>FREE Google Friendly Sitemap</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Complete W3C Certified HTML</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>48 to 72 hours TAT</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>Complete Deployment</small>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <small>100% Satisfaction Guaranteet</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                        <span>Get Started Now</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Commonprice;