import React from "react";
import './contact.css';
import Innerbanner from "./Innerbanner";
import contactbanner from '../images/innerbanner/contactbanner.jpg';

const Contact = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Innerbanner
                title="We specialise for our customers their business"
                homebread="Home"
                breadcontent="Contact Us"
                imgsrc={contactbanner} />
            <section id="contact_area" className="contact_area">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="contact_icon">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Phone No</h5>
                                    <p>
                                        <a href="tel:7980717542">+91 7980717542</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="contact_icon">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Email Address</h5>
                                    <p>
                                        <a href="mailto:info@infinixsoftwares.com">info@infinixsoftwares.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card text-center">
                                <div className="contact_icon">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Address</h5>
                                    <p>
                                        10/1 Collin Lane Kolkata Kolkata WB 700016 In <br />
                                        CIN - U72900WB2019PTC234541
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;