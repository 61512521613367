import React from "react";
import { Link } from "react-router-dom";
import Chat_card from "./Chat_card";
import Innerbanner from "./Innerbanner";
import './package.css';
import package_banner from '../images/innerbanner/package_banner.jpg';

const Package = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Innerbanner
                title="Investigate our reasonable bundles to build a websites"
                homebread="Home"
                breadcontent="Packages"
                imgsrc={package_banner} />

            <section id="price_area" className="price_area">
                <div className="container">
                    <div className="section-head style-3 d-flex align-items-center justify-content-center">
                        <h3>Our <span>Package</span></h3>
                    </div>
                    <div className="body_widget text-center">
                        <p>
                            Take a look at our affordable packages to build a website <br /> with a greater level of functionality compared to traditional websites.
                        </p>
                    </div>
                    <div className="mt-5">
                        <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link active" data-bs-toggle="pill" to="#web_design">Web Design</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" data-bs-toggle="pill" to="#ecommerce">Ecommerce</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" data-bs-toggle="pill" to="#branding">Branding</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" data-bs-toggle="pill" to="#react">React.JS</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content">
                        <div id="web_design" className="tab-pane active">
                            <div className="price-cards">
                                <div className="row my-5">
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Standard</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$199.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>3 Stock Photos</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>3 Banner Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>1 Page Static Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>1 jQuery Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>48 to 72 hours TAT</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Additional Mobile Responsive ($99)</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card dark-card style-3">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Pro <small>Recommended</small></h4>
                                                    <small>Premium features</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$399.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Unique Pages Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>CMS / Admin Panel Support</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Stock images </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>5 Banner Designs </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>1 jQuery Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>48 to 72 hours TAT</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Deployment</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guaranteet</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Additional Mobile Responsive ($99)</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Elite</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$799.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Conceptual and Dynamic Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Mobile Responsive</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Online Payment Integration (Optional)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Custom Forms</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Striking Hover Effects</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Newsfeed Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Social Media Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Stock images </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>5 Banner Designs </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>1 jQuery Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>48 to 72 hours TAT</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Deployment</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guaranteet</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="ecommerce" className="tab-pane fade">
                            <div className="price-cards">
                                <div className="row my-5">
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Beginners</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$999.99</h5>
                                                    <small>per month</small>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Conceptual and Dynamic Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Mobile Responsive</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Content Management System (CMS)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Easy Product Search</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Product Reviews</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 100 Products</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 7 Categories</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Full Shopping Cart Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Payment Module Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Sales Inventory Management</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Jquery Slider</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Free Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Facebook Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Twitter Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>YouTube Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Industry Specified Team of Expert Designers and Developers</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Dedicated Accounts Manager</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Deployment</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Ownership Rights</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card dark-card style-3">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Professional</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$1199.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Conceptual and Dynamic Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Mobile Responsive</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Content Management System (CMS)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Easy Product Search</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Product Reviews</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 250 Products</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 15 Categories</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Full Shopping Cart Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Payment Module Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Sales Inventory Management</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Jquery Slider</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Free Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Facebook Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Twitter Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>YouTube Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Industry Specified Team of Expert Designers and Developers</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Dedicated Accounts Manager</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Deployment</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Ownership Rights</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Corporate</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$1449.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Conceptual and Dynamic Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Mobile Responsive</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Content Management System (CMS)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Easy Product Search</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Product Reviews</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 300 Products</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Up To 30 Categories</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Full Shopping Cart Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Payment Module Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Sales Inventory Management</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Jquery Slider</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Free Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Facebook Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Twitter Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>YouTube Page Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Industry Specified Team of Expert Designers and Developers</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Dedicated Accounts Manager</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Deployment</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Ownership Rights</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="branding" className="tab-pane fade">
                            <div className="price-cards">
                                <div className="row my-5">
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Startup</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$99.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>2 Stationery Design Set</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Fax Template</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Print Ready Formats</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Unlimited Revisions</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card dark-card style-3">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Classic</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$199.00</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>2 Stationery Design Set</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Fax Template</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Flyer Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Brochure Design (Bi-fold/Tri-fold)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Premium</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$399.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>2 Stationery Design Set</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Packaging Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>T-shirt Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Unlimited Revisions</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="react" className="tab-pane fade">
                            <div className="price-cards">
                                <div className="row my-5">
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Standard</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$199.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>3 Stock Photos</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>3 Banner Design</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>3 Page Static Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>1 React Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>48 to 72 hours TAT</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Additional Mobile Responsive ($99)</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card dark-card style-3">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Pro <small>Recommended</small></h4>
                                                    <small>Premium features</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$399.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Unique Pages Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete API Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Stock images </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>6 Banner Designs </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>2 React Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Dynamic App</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guaranteet</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Unique Design Guarantee </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Additional Mobile Responsive ($99)</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="pricing-card style-3 mb-30 mb-lg-0">
                                            <div className="card-head">
                                                <div className="title">
                                                    <h4>Elite</h4>
                                                    <small>Starting at</small>
                                                </div>
                                                <div className="price">
                                                    <h5>$799.99</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Conceptual and Dynamic Website</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Mobile Responsive</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Online Payment Integration (Optional)</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Striking Hover Effects</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Api Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Social Media Integration</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>8 Stock images </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>5 Banner Designs </small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>4 React Slider Banner</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>FREE Google Friendly Sitemap</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete W3C Certified HTML</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>Complete Dynamic App</small>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <small>100% Satisfaction Guaranteet</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Link to="/contact" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                                <span>Get Started Now</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Chat_card />
        </>
    )
}

export default Package;