import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import wordpressdevelopmentbanner from '../images/services/wordpress-development.jpg';
import Commonservices from "./Commonservices";
import wordpressright from '../images/services/wordpressright.jpg';
import advancements from '../images/services/advancements.jpg';

const Wordpressdevelopment = () => {
    return (
        <>
            <Innerbanner 
            title="Your Web-based Thriving Can Be Upgraded." 
            homebread="Home" 
            breadcontent="Services / Wordpress Development"
            imgsrc={wordpressdevelopmentbanner} />
            <Commonservices title="Wordpress"
                subtitle="Development"
                discription="Site Imaginative is a trailblazer in WordPress improvement administrations, and we can assist you with taking your organization to a higher level. We're a Los Angeles-based WordPress improvement firm, and we like to consider ourselves a key part in the seaward and rethink industry. With long stretches of involvement under our belts, we offer far reaching WordPress administrations, from PSD to WordPress transformation and module and subject creation to WordPress movement administrations."
                alt="Wordpress Development"
                imgsrc={wordpressright}
                titlesecond="Complete web stack" 
                subtitlesecond="advancements"
                discription1="Server-side and client-side advances and instruments that can be utilized to streamline web application speed and the client experience."
                discription2="An upper hand and the acknowledgment of your vision are the two things you want from the procedure and improvement administrations you recruit. At the point when you enlist infinix software, you'll approach a staff that is conversant in excess of 50 different programming dialects and structures."
                discription3="Our answers are customized to your exceptional necessities, instead of the cutout approaches of contending firms."
                imgsrcsecond={advancements} />
            <Chat_card />
        </>
    )
}

export default Wordpressdevelopment;