import React from "react";
import { Link } from "react-router-dom";
import portfolio1 from '../images/portfolio/portfolio1.jpg';
import portfolio2 from '../images/portfolio/portfolio2.jpg';
import portfolio3 from '../images/portfolio/portfolio3.jpg';
import portfolio4 from '../images/portfolio/portfolio4.jpg';

const Portfolio_card = () => {
    return (
        <>
            <section className="projects section-padding style-3">
                <div className="container">
                    <div className="section-head style-3 text-center">
                        <h3>Our Featured <span>Projects</span></h3>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="project-card d-block mb-30 style-3">
                                    <Link to="#" className="img img-cover d-block">
                                        <img src={portfolio1} alt="" />
                                    </Link>
                                    <div className="info">
                                        <h5 className="h5"><Link to="#">Get An Intervention</Link></h5>
                                        <small className="small"><Link to="#">Web Design</Link></small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="project-card d-block mb-30 style-3">
                                    <Link to="#" className="img img-cover d-block">
                                        <img src={portfolio2} alt="" />
                                    </Link>
                                    <div className="info">
                                        <h5 className="h5"><Link to="#">v2s Payments</Link></h5>
                                        <small className="small"><Link to="#">Mobile Recharge</Link></small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="project-card d-block style-3 mb-30 mb-lg-0">
                                    <Link to="#" className="img img-cover d-block">
                                        <img src={portfolio3} alt="" />
                                    </Link>
                                    <div className="info">
                                        <h5 className="h5"><Link to="#">Hello Bondhu</Link></h5>
                                        <small className="small"><Link to="#">Web Design</Link></small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="project-card d-block style-3">
                                    <Link to="#" className="img img-cover d-block">
                                        <img src={portfolio4} alt="" />
                                    </Link>
                                    <div className="info">
                                        <h5 className="h5"><Link to="#">Minerals</Link></h5>
                                        <small className="small"><Link to="#">Web Development</Link></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/portfolio" className="btn rounded-pill bg-blue2 sm-butn mt-60 text-white">
                                <span>See All Projects</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Portfolio_card;