import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import './portfolio.css';
import Pcard from "./Pcard";
import Commonport from "./Common_porfolio";
import portfolio_banner from '../images/innerbanner/portfolio_banner.jpg';

const Portfolio = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Innerbanner 
            title="Work Express Stronger than words" 
            homebread="Home" 
            breadcontent="Portfolio"
            imgsrc={portfolio_banner} />
            <section id="portfolio_area" className="portfolio_area">
                <div className="container">
                    <div className="section-head style-3 d-flex align-items-center">
                        <h3>Our Main <span>Portfolio</span></h3>
                    </div>
                    <div className="row gy-4">
                        {
                            Pcard.map((val, ind) => {
                                return <Commonport key={ind} imgsrc={val.imgsrc} title={val.title} discription={val.discription} />
                            })
                        }
                    </div>
                </div>
            </section>
            <Chat_card />
        </>
    )
}

export default Portfolio;