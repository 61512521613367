import React from "react";
import Commonprice from "./Commonprice";
import './service_common.css';

const Commonservices = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <section id="services_common" className="services_common">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="services_widget">
                                <div className="section-head style-3 d-flex align-items-center">
                                    <h3>{props.title} <span>{props.subtitle}</span></h3>
                                </div>
                                <p>
                                   {props.discription} 
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="services_widget">
                                <img src={props.imgsrc} className="services_img" alt={props.alt} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Commonprice />
            <section id="services_second" className="services_common">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="services_widget">
                                <img src={props.imgsrcsecond} className="services_img" alt={props.alt} />
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="services_widget">
                                <div className="section-head style-3 d-flex align-items-center">
                                    <h3>{props.titlesecond} <span>{props.subtitlesecond}</span></h3>
                                </div>
                                <p>
                                    {props.discription1}
                                </p>
                                <p>
                                    {props.discription2}
                                </p>
                                <p>
                                    {props.discription3}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Commonservices;