import React from "react";
import Innerbanner from "./Innerbanner";
import Chat_card from "./Chat_card";
import Webapplicationdevelopmentbanner from '../images/services/web-development-banner.jpg';
import Commonservices from "./Commonservices";
import webright from '../images/services/webright.jpg';
import webcoding from '../images/services/webcoding.jpg';

const Webapplicationdevelopment = () => {
    return (
        <>
            <Innerbanner 
            title="Web Application Improvement Systems" 
            homebread="Home" 
            breadcontent="Services / Web Application Development"
            imgsrc={Webapplicationdevelopmentbanner} />
            <Commonservices title="Web Application"
                subtitle="Development"
                discription="Administrations for Creating Interesting Web Applications, Design is strong A web application improvement business with more than decade of involvement with making first rate B2B and B2C arrangements with an accentuation on video dissemination, RTC, adtech, eLearning, and information investigation is called Site Innovative. Throughout quite a while, in excess of 200 clients have gotten items from our serious web application improvement specialists. As a chief supplier of web application improvement, we take special care of the computerized necessities of probably the most flourishing areas. We give the best innovation and technique mix after cautiously analyzing the points of interest of every business area to assist you with defeating your issues."        
                alt="Web Application Development"
                imgsrc={webright}
                titlesecond="Complete web stack" 
                subtitlesecond="advancements"
                discription1="Server-side and client-side advances and instruments that can be utilized to streamline web application speed and the client experience."
                discription2="An upper hand and the acknowledgment of your vision are the two things you want from the procedure and improvement administrations you recruit. At the point when you enlist infinix software, you'll approach a staff that is conversant in excess of 50 different programming dialects and structures."
                discription3="Our answers are customized to your exceptional necessities, instead of the cutout approaches of contending firms."
                imgsrcsecond={webcoding} />   
            <Chat_card />
        </>
    )
}

export default Webapplicationdevelopment;