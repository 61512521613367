import React from "react";
import Banner from "./banner";
import Chat_card from "./Chat_card";
import Mission from "./Mission";
import Portfolio_card from "./Portfolio_card";
import Service from "./Services_card";
import Testimonials_card from "./Testimonials_card";

const Home = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Banner/>
            <Service />
            <Mission />
            <Portfolio_card />
            <Chat_card />
        </>
    )
}
export default Home;