import React from "react";
import { Link } from "react-router-dom";

const Mission = () => {
    return (
        <>
            <section className="about section-padding style-3">
                <div className="top-content">
                    <div className="img img-left">
                        <img src="img/about/style_3_1.png" alt="" />
                        <div className="info-circle">
                            <div className="cont">
                                <h2>1,2k</h2>
                                <small>Projects done</small>
                            </div>
                        </div>
                        <div className="info-circle">
                            <div className="cont">
                                <h2>165</h2>
                                <small>satisfied clients</small>
                            </div>
                        </div>
                        <div className="info-circle">
                            <div className="cont">
                                <h2>26</h2>
                                <small>awards winner</small>
                            </div>
                        </div>
                        <div className="info-circle">
                            <div className="cont">
                                <h2>15</h2>
                                <small>years of experience</small>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row gx-0 justify-content-end">
                            <div className="col-lg-5">
                                <div className="info">
                                    <div className="section-head long-shape mb-40 style-3">
                                        <h3>Infinix <span>Mission & Vision</span></h3>
                                    </div>
                                    <h5 className="h5">
                                        We began with only 2 representatives working enthusiastically with a dream to develop.
                                    </h5>
                                    <div className="text mb-20">
                                        With most extreme commitment, we sacked our most memorable brand project in something like a month of our fuse.
                                    </div>
                                    <div className="text mb-30">
                                        Set the justification for progress and remarkable open doors. We touched a 20+ project milestone mark and strengthened our workforce by hiring 10+ employees all of this in just a year. Ramped up all our processes to incorporate a smooth flow of work.
                                    </div>
                                    <Link to="/about" className="btn rounded-pill bg-blue2 sm-butn text-white">
                                        <span>Read More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btm-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="info pe-lg-5">
                                    <div className="section-head mb-40 style-3">
                                        <h3>Infinix <span>Technology</span></h3>
                                    </div>
                                    <div className="text mb-30">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    </div>
                                    <div className="text mb-30">
                                        <strong>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here</strong>
                                    </div>
                                    <Link to="#l" className="btn rounded-pill border-blue2 hover-blue2 sm-butn">
                                        <span>How We Works</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img img-right">
                        <img src="img/about/style_3_2.png" alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mission;